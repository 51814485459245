import { render, staticRenderFns } from "./HomeInternoAlunoAtendimento.vue?vue&type=template&id=29164179"
import script from "./HomeInternoAlunoAtendimento.vue?vue&type=script&lang=js"
export * from "./HomeInternoAlunoAtendimento.vue?vue&type=script&lang=js"
import style0 from "./HomeInternoAlunoAtendimento.vue?vue&type=style&index=0&id=29164179&prod&scope=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports